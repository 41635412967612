import fonts from './fonts';
import spacing from './space';

const typography = {
  HeadingSmall600: {
    fontFamily: fonts.semiBold,
    fontSize: spacing['spacing-24'],
    fontWeight: fonts.weight.semiBold,
    lineHeight: spacing['spacing-32'],
    letterSpacing: fonts.spacing.default,
  },
  HeadingDefault600: {
    fontFamily: fonts.semiBold,
    fontSize: spacing['spacing-32'],
    fontWeight: fonts.weight.semiBold,
    letterSpacing: fonts.weight.semiBold,
    lineHeight: spacing['spacing-40'],
  },
  LabelXlarge500: {
    fontFamily: fonts.medium,
    fontSize: '22px',
    fontWeight: fonts.weight.medium,
    lineHeight: spacing['spacing-28'],
    letterSpacing: fonts.spacing.default,
  },
  LabelXlarge400: {
    fontFamily: fonts.default,
    fontSize: '22px',
    fontWeight: fonts.weight.regular,
    lineHeight: spacing['spacing-28'],
    letterSpacing: fonts.spacing.default,
  },
  LabelLarge500: {
    fontFamily: fonts.medium,
    fontSize: '18px',
    fontWeight: fonts.weight.medium,
    letterSpacing: fonts.spacing.default,
    lineHeight: spacing['spacing-24'],
  },
  LabelLarge400: {
    fontFamily: fonts.default,
    fontSize: '18px',
    fontWeight: fonts.weight.regular,
    letterSpacing: fonts.spacing.default,
    lineHeight: spacing['spacing-24'],
  },
  LabelDefault400: {
    fontFamily: fonts.default,
    fontSize: spacing['spacing-16'],
    fontWeight: fonts.weight.regular,
    letterSpacing: '',
    lineHeight: spacing['spacing-20'],
  },
  LabelDefault500: {
    fontFamily: fonts.medium,
    fontSize: spacing['spacing-16'],
    fontWeight: fonts.weight.medium,
    letterSpacing: spacing['spacing-0'],
    lineHeight: spacing['spacing-20'],
  },
  LabelSmall400: {
    fontFamily: fonts.default,
    fontSize: '14px',
    fontWeight: fonts.weight.regular,
    letterSpacing: fonts.spacing.large,
    lineHeight: spacing['spacing-16'],
  },
  LabelSmall500: {
    fontFamily: fonts.medium,
    fontSize: '14px',
    fontWeight: fonts.weight.medium,
    letterSpacing: fonts.spacing.large,
    lineHeight: spacing['spacing-16'],
  },
  LabelXsmall400: {
    fontFamily: fonts.default,
    fontSize: '12px',
    fontWeight: fonts.weight.regular,
    letterSpacing: fonts.spacing.xlarge,
    lineHeight: spacing['spacing-16'],
  },
  LabelXsmall500: {
    fontFamily: fonts.medium,
    fontSize: '12px',
    fontWeight: fonts.weight.medium,
    letterSpacing: fonts.spacing.xlarge,
    lineHeight: spacing['spacing-16'],
  },
  LabelXXsmall400: {
    fontFamily: fonts.default,
    fontSize: '10px',
    fontWeight: fonts.weight.regular,
    letterSpacing: fonts.spacing.xlarge,
    lineHeight: spacing['spacing-14'],
  },
  LabelXXsmall500: {
    fontFamily: fonts.medium,
    fontSize: '10px',
    fontWeight: fonts.weight.medium,
    letterSpacing: fonts.spacing.xlarge,
    lineHeight: spacing['spacing-14'],
  },
  BodyDefault400: {
    fontFamily: fonts.default,
    fontSize: spacing['spacing-16'],
    fontWeight: fonts.weight.regular,
    lineHeight: spacing['spacing-24'],
  },
  BodyDefault500: {
    fontFamily: fonts.medium,
    fontSize: spacing['spacing-16'],
    fontWeight: fonts.weight.medium,
    lineHeight: spacing['spacing-24'],
  },
  BodyDefault600: {
    fontFamily: fonts.medium,
    fontSize: spacing['spacing-16'],
    fontWeight: fonts.weight.semiBold,
    lineHeight: spacing['spacing-24'],
  },
  BodyLarge400: {
    fontFamily: fonts.default,
    fontSize: '18px',
    fontWeight: fonts.weight.regular,
    lineHeight: spacing['spacing-28'],
  },
  BodyLarge500: {
    fontFamily: fonts.medium,
    fontSize: '18px',
    fontWeight: fonts.weight.medium,
    lineHeight: spacing['spacing-28'],
  },
};

export default typography;
