import { IIconProps } from '../interface';

const SvgEstonia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 28C0 31.425 0.616109 34.7058 1.74158 37.7391L28 38.9565L54.2584 37.7392C55.384 34.7058 56 31.425 56 28C56 24.575 55.384 21.2942 54.2584 18.2609L28 17.0435L1.74158 18.2608C0.616109 21.2942 0 24.575 0 28H0Z"
      fill="black"
    />
    <path
      d="M28 0C15.961 0 5.69778 7.5985 1.74158 18.2609H54.2585C50.3022 7.5985 40.039 0 28 0Z"
      fill="#0052B4"
    />
    <path
      d="M54.2584 37.7391H1.74158C5.69778 48.4014 15.961 56 28 56C40.039 56 50.3022 48.4014 54.2584 37.7391Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgEstonia;
