import { IIconProps } from '../interface';

const SvgItaly = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15896)">
      <path
        d="M28 56.418C43.464 56.418 56 43.8819 56 28.418C56 12.954 43.464 0.417969 28 0.417969C12.536 0.417969 0 12.954 0 28.418C0 43.8819 12.536 56.418 28 56.418Z"
        fill="#F0F0F0"
      />
      <path
        d="M56.0001 28.418C56.0001 16.379 48.4016 6.11575 37.7391 2.15955V54.6765C48.4016 50.7202 56.0001 40.457 56.0001 28.418Z"
        fill="#D80027"
      />
      <path
        d="M0 28.418C0 40.457 7.5985 50.7202 18.2609 54.6764V2.15955C7.5985 6.11575 0 16.379 0 28.418Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15896">
        <rect
          width="56"
          height="57"
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgItaly;
