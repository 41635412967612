import { IIconProps } from '../interface';

const SvgLiechtenstein = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C1.21734 28 28 24.3479 28 24.3479L56 28Z"
      fill="#D80027"
    />
    <path
      d="M0 28C0 12.5361 12.5361 0 28 0C43.4639 0 56 12.5361 56 28"
      fill="#0052B4"
    />
    <path
      d="M20.6956 19.4783C20.6956 17.4613 19.0604 15.8261 17.0434 15.8261C16.1077 15.8261 15.2549 16.1783 14.6086 16.7568V14.6087H15.826V12.1739H14.6086V10.9565H12.1738V12.1739H10.9565V14.6087H12.1738V16.7568C11.5276 16.1783 10.6748 15.8261 9.73905 15.8261C7.72207 15.8261 6.08691 17.4613 6.08691 19.4783C6.08691 20.5597 6.55734 21.5309 7.30426 22.1997V24.3479H19.4781V22.1997C20.2253 21.5309 20.6956 20.5597 20.6956 19.4783V19.4783Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgLiechtenstein;
