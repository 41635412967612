import { IIconProps } from '../interface';

const SvgIndia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 0C16.8991 0 7.30732 6.46023 2.77832 15.8261H53.2215C48.6926 6.46023 39.1008 0 28 0Z"
      fill="#FF9811"
    />
    <path
      d="M28 56C39.1008 56 48.6926 49.5398 53.2216 40.1739H2.77832C7.30732 49.5398 16.8991 56 28 56Z"
      fill="#6DA544"
    />
    <path
      d="M27.9999 37.7391C33.3787 37.7391 37.739 33.3787 37.739 28C37.739 22.6213 33.3787 18.2609 27.9999 18.2609C22.6212 18.2609 18.2609 22.6213 18.2609 28C18.2609 33.3787 22.6212 37.7391 27.9999 37.7391Z"
      fill="#0052B4"
    />
    <path
      d="M28 34.0869C31.3617 34.0869 34.087 31.3617 34.087 28C34.087 24.6383 31.3617 21.9131 28 21.9131C24.6383 21.9131 21.9131 24.6383 21.9131 28C21.9131 31.3617 24.6383 34.0869 28 34.0869Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 20.4888L29.8778 24.7476L34.5048 24.2444L31.7556 28L34.5048 31.7556L29.8778 31.2524L28 35.5112L26.1221 31.2524L21.4951 31.7555L24.2444 28L21.4951 24.2444L26.1221 24.7476L28 20.4888Z"
      fill="#0052B4"
    />
  </svg>
);

export default SvgIndia;
