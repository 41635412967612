const base = {
  white: '#FFFFFF',
  black: '#000000',
  platinum50: '#F4FAFB',
  platinum100: '#EBF5F7',
  platinum200: '#CCDFE5',
  platinum300: '#A1BDCA',
  platinum400: '#8EA3AD',
  platinum500: '#6C7C83',
  platinum600: '#556268',
  platinum700: '#394145',
  platinum800: '#142328',
  cobalt50: '#EBEDFA',
  cobalt100: '#D2D7F0',
  cobalt200: '#949CE3',
  cobalt300: '#535FCF',
  cobalt400: '#0E1FC1',
  cobalt500: '#0A1899',
  cobalt600: '#081270',
  cobalt700: '#050C4D',
  brown50: '#F6F0EA',
  brown100: '#EBE0DB',
  brown200: '#D2BBB0',
  brown300: '#B18977',
  brown400: '#99644C',
  brown500: '#744C3A',
  brown600: '#5C3C2E',
  brown700: '#3D281E',
  slate50: '#f8fafc',
  slate100: '#f1f5f9',
  slate200: '#e2e8f0',
  slate300: '#cbd5e1',
  slate400: '#94a3b8',
  slate500: '#64748b',
  slate600: '#475569',
  slate700: '#334155',
  slate800: '#1e293b',
  slate900: '#0f172a',
  gray25: '#FAFAFA',
  gray50: '#F6F6F6',
  gray100: '#EFEFF0',
  gray200: '#E5E4E7',
  gray300: '#CDCDD0',
  gray400: '#AEAEB2',
  gray500: '#777A83',
  gray600: '#555962',
  gray700: '#4F5059',
  gray800: '#272B35',
  gray900: '#1B1C22',
  zinc50: '#fafafa',
  zinc100: '#f4f4f5',
  zinc200: '#e4e4e7',
  zinc300: '#d4d4d8',
  zinc400: '#a1a1aa',
  zinc500: '#71717a',
  zinc600: '#52525b',
  zinc700: '#3f3f46',
  zinc800: '#27272a',
  zinc900: '#18181b',
  neutral50: '#fafafa',
  neutral100: '#f5f5f5',
  neutral200: '#e5e5e5',
  neutral300: '#d4d4d4',
  neutral400: '#a3a3a3',
  neutral500: '#737373',
  neutral600: '#525252',
  neutral700: '#404040',
  neutral800: '#262626',
  neutral900: '#171717',
  stone50: '#fafaf9',
  stone100: '#f5f5f4',
  stone200: '#e7e5e4',
  stone300: '#d6d3d1',
  stone400: '#a8a29e',
  stone500: '#78716c',
  stone600: '#57534e',
  stone700: '#44403c',
  stone800: '#292524',
  stone900: '#1c1917',
  red25: '#FDF7F7',
  red50: '#FCEEEE',
  red100: '#F7DFDE',
  red200: '#F6CCCB',
  red300: '#F1A9A7',
  red400: '#E87874',
  red500: '#DE504A',
  red600: '#C83B32',
  red700: '#A72D25',
  red800: '#8F261F',
  red900: '#691E1B',
  red950: '#FF4747',
  orange50: '#fff7ed',
  orange100: '#ffedd5',
  orange200: '#fed7aa',
  orange300: '#fdba74',
  orange400: '#fb923c',
  orange500: '#f97316',
  orange600: '#ea580c',
  orange700: '#c2410c',
  orange800: '#9a3412',
  orange900: '#7c2d12',
  amber50: '#fffbeb',
  amber100: '#fef3c7',
  amber200: '#fde68a',
  amber300: '#fcd34d',
  amber400: '#fbbf24',
  amber500: '#f59e0b',
  amber600: '#d97706',
  amber700: '#b45309',
  amber800: '#92400e',
  amber900: '#78350f',
  yellow50: '#fefce8',
  yellow100: '#fef9c3',
  yellow200: '#fef08a',
  yellow300: '#fde047',
  yellow400: '#facc15',
  yellow500: '#eab308',
  yellow600: '#ca8a04',
  yellow700: '#a16207',
  yellow800: '#854d0e',
  yellow900: '#713f12',
  lime50: '#f7fee7',
  lime100: '#ecfccb',
  lime200: '#d9f99d',
  lime300: '#bef264',
  lime400: '#a3e635',
  lime500: '#84cc16',
  lime600: '#65a30d',
  lime700: '#4d7c0f',
  lime800: '#3f6212',
  lime900: '#365314',
  green25: '#F3FDF5',
  green50: '#E9FBED',
  green100: '#D7F9E0',
  green200: '#BEF4CB',
  green300: '#96E8AA',
  green400: '#71D682',
  green500: '#4DBC5B',
  green600: '#48994F',
  green700: '#38753F',
  green800: '#24512C',
  green900: '#17361D',
  emerald50: '#ecfdf5',
  emerald100: '#d1fae5',
  emerald200: '#a7f3d0',
  emerald300: '#6ee7b7',
  emerald400: '#34d399',
  emerald500: '#10b981',
  emerald600: '#059669',
  emerald700: '#047857',
  emerald800: '#065f46',
  emerald900: '#064e3b',
  teal50: '#f0fdfa',
  teal100: '#ccfbf1',
  teal200: '#99f6e4',
  teal300: '#5eead4',
  teal400: '#2dd4bf',
  teal500: '#14b8a6',
  teal600: '#0d9488',
  teal700: '#0f766e',
  teal800: '#115e59',
  teal900: '#134e4a',
  cyan50: '#ecfeff',
  cyan100: '#cffafe',
  cyan200: '#a5f3fc',
  cyan300: '#67e8f9',
  cyan400: '#22d3ee',
  cyan500: '#06b6d4',
  cyan600: '#0891b2',
  cyan700: '#0e7490',
  cyan800: '#155e75',
  cyan900: '#164e63',
  sky50: '#f0f9ff',
  sky100: '#e0f2fe',
  sky200: '#bae6fd',
  sky300: '#7dd3fc',
  sky400: '#38bdf8',
  sky500: '#0ea5e9',
  sky600: '#0284c7',
  sky700: '#0369a1',
  sky800: '#075985',
  sky900: '#0c4a6e',
  blue50: '#eff6ff',
  blue100: '#dbeafe',
  blue200: '#bfdbfe',
  blue300: '#93c5fd',
  blue400: '#60a5fa',
  blue500: '#3b82f6',
  blue600: '#2563eb',
  blue700: '#1d4ed8',
  indigo50: '#EFF2FE',
  blue800: '#1e40af',
  indigo100: '#E1E7FD',
  blue900: '#1e3a8a',
  indigo200: '#CCD4FA',
  indigo300: '#ACB7F6',
  indigo400: '#838CF1',
  indigo500: '#6466E9',
  indigo600: '#4E46DC',
  indigo700: '#4037BE',
  indigo800: '#302B8D',
  indigo900: '#1E1D4E',
  violet50: '#f5f3ff',
  violet100: '#ede9fe',
  violet200: '#ddd6fe',
  violet300: '#c4b5fd',
  violet400: '#a78bfa',
  violet500: '#8b5cf6',
  violet600: '#7c3aed',
  violet700: '#6d28d9',
  violet800: '#5b21b6',
  violet900: '#4c1d95',
  purple50: '#faf5ff',
  purple100: '#f3e8ff',
  purple200: '#e9d5ff',
  purple300: '#d8b4fe',
  purple400: '#c084fc',
  purple500: '#a855f7',
  purple600: '#9333ea',
  purple700: '#7e22ce',
  purple800: '#6b21a8',
  purple900: '#581c87',
  fuchsia50: '#fdf4ff',
  fuchsia100: '#fae8ff',
  fuchsia200: '#f5d0fe',
  fuchsia300: '#f0abfc',
  fuchsia400: '#e879f9',
  fuchsia500: '#d946ef',
  fuchsia600: '#c026d3',
  fuchsia700: '#a21caf',
  fuchsia800: '#86198f',
  fuchsia900: '#701a75',
  pink50: '#fdf2f8',
  pink100: '#fce7f3',
  pink200: '#fbcfe8',
  pink300: '#f9a8d4',
  pink400: '#f472b6',
  pink500: '#ec4899',
  pink600: '#db2777',
  pink700: '#be185d',
  pink800: '#9d174d',
  pink900: '#831843',
  rose50: '#fff1f2',
  rose100: '#ffe4e6',
  rose200: '#fecdd3',
  rose300: '#fda4af',
  rose400: '#fb7185',
  rose500: '#f43f5e',
  rose600: '#e11d48',
  rose700: '#be123c',
  rose800: '#9f1239',
  rose900: '#881337',
  turquoise100: '#D1F0EA',
  dropzoneLightRose: '#FEE1DC',
  dropzoneStrongRose: '#C7230A',
  dropzoneLightBlue: '#E2E7FD',
  dropZoneMediumBlue: '#738AF7',
  dropZoneStrongBlue: '#122EB5',
  dropZoneLightGray: '#F2F2F3',
  dropZoneMediumGray: '#D3D2D5',
  pageActionStrongGray: '#6B6C76',
};

const neutral = {
  field: base.gray100,
  fieldHover: base.gray200,
  fieldActive: base.gray300,
  background: base.white,
  backgroundWeak: base.gray50,
  text: base.gray800,
  textHint: base.gray500,
  textWeak: base.gray600,
  textDisabled: base.gray400,
  textStrong: base.gray900,
  border: base.gray200,
  borderActive: base.gray500,
  borderHover: base.gray300,
  borderStrong: base.gray300,
  icon: base.gray600,
  iconActive: base.gray900,
  iconHover: base.gray900,
};

const interactive = {
  field: base.cobalt50,
  fieldHover: base.cobalt100,
  fieldActive: base.cobalt200,
  backgroundStrong: base.cobalt400,
  backgroundStrongActive: base.cobalt600,
  backgroundStrongDisabled: base.cobalt50,
  backgroundStrongHover: base.cobalt500,
  backgroundWeak: base.cobalt50,
  text: base.cobalt400,
  textHover: base.cobalt500,
  textSecondary: base.cobalt300,
  textDisabled: base.cobalt200,
  border: base.cobalt200,
  borderActive: base.cobalt500,
  borderHover: base.cobalt300,
  borderStrong: base.cobalt300,
  icon: base.cobalt300,
  iconActive: base.cobalt500,
  iconHover: base.cobalt500,
};

const positive = {
  field: base.green100,
  fieldHover: base.green200,
  fieldActive: base.green300,
  backgroundStrong: base.green400,
  backgroundStrongActive: base.green600,
  backgroundStrongDisabled: base.green50,
  backgroundStrongHover: base.green500,
  backgroundWeak: base.green50,
  text: base.green600,
  textHover: base.green700,
  textSecondary: base.green500,
  textDisabled: base.gray400,
  border: base.green200,
  borderActive: base.green500,
  borderHover: base.green300,
  borderStrong: base.green300,
};

const negative = {
  field: base.red100,
  fieldHover: base.red200,
  fieldActive: base.red300,
  backgroundStrong: base.red400,
  backgroundStrongActive: base.red600,
  backgroundStrongDisabled: base.red50,
  backgroundStrongHover: base.red500,
  backgroundWeak: base.red50,
  text: base.red600,
  textHover: base.red700,
  textWeak: base.red500,
  textDisabled: base.gray400,
  border: base.red200,
  borderActive: base.red500,
  borderHover: base.red300,
  borderStrong: base.red300,
};

const colors = {
  base,
  neutral,
  interactive,
  positive,
  negative,
};

export default colors;
