import { ReactElement, useEffect } from 'react';

import Button from '../Button';
import Icon from '../Icon';

interface ILightbox {
  children: ReactElement;
  buttonLabel?: string;
  buttonAction?: () => void;
  closeActtion: () => void;
  visible: boolean;
  closeButton?: boolean;
  noPadding?: boolean;
  noFlex?: boolean;
  className?: string;
}

const Lightbox = ({
  children,
  buttonLabel,
  buttonAction,
  closeActtion,
  visible,
  closeButton = true,
  noPadding = false,
  noFlex = false,
  className,
}: ILightbox) => {
  useEffect(() => {
    if (visible) {
      document.querySelector('html')?.classList.add('overflow-hidden');
      document.querySelector('body')?.classList.add('overflow-hidden');
      return;
    }

    document.querySelector('html')?.classList.remove('overflow-hidden');
    document.querySelector('body')?.classList.remove('overflow-hidden');
  }, [visible]);

  return (
    <>
      <div
        className={`fixed top-0 left-0 w-full h-full flex items-center justify-center transition-all ${
          visible
            ? 'opacity-100 pointer-events-auto z-30'
            : 'opacity-0 pointer-events-none -z-30'
        }`}
        style={{ backgroundColor: 'rgba(0, 0, 0, .56)' }}
      >
        <div
          className="fixed top-0 left-0 w-full h-full"
          onClick={closeActtion}
        ></div>
        <div
          className={`${className} ${
            noPadding ? '' : 'pt-10'
          } bg-white w-full max-w-xl rounded-2xl flex relative`}
          style={{
            maxHeight: '90%',
            minHeight: '60%',
            flexDirection: 'column',
          }}
        >
          <div
            className={`absolute top-3 right-3 ${
              closeButton ? 'visible' : 'invisible'
            }`}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                closeActtion();
              }}
            >
              <Icon name="Close" type="sm" />
            </button>
          </div>
          <div
            className={`${noPadding ? 'p-0' : 'px-8'} ${
              noFlex ? '' : 'flex flex-1'
            } max-h-full overflow-auto`}
          >
            {children}
          </div>
          {buttonAction && buttonLabel && (
            <div className="border-t-1 border-gray-200 mt-8 pt-6 pb-8 flex justify-end px-8">
              <Button variant="primary" size="medium" onClick={buttonAction}>
                {buttonLabel}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Lightbox;
