import { Outlet } from 'react-router-dom';
import { Grid } from '../../design-system';
import Sidebar from '../sidebar';

const AppLayout = () => {
  return (
    <Grid
      className="App flex h-screen bg-base-gray25 flex-col justify-between"
      fluid
      noPadding
    >
      <Grid noPadding fluid className="msm:flex-col">
        <Sidebar variant="APP" />
        <div className="w-full md:pl-spacing-270 md:pr-spacing-128">
          <Outlet />
        </div>
      </Grid>
    </Grid>
  );
};

export default AppLayout;
