import { IIconProps } from '../interface';

const SvgMexico = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M56 28C56 16.8991 49.5397 7.30735 40.1738 2.77835V53.2215C49.5397 48.6927 56 39.1009 56 28Z"
      fill="#D80027"
    />
    <path
      d="M0 28C0 39.1009 6.46023 48.6927 15.8261 53.2217V2.77835C6.46023 7.30735 0 16.8991 0 28Z"
      fill="#6DA544"
    />
    <path
      d="M20.6956 28C20.6956 32.0341 23.9659 35.3044 27.9999 35.3044C32.034 35.3044 35.3043 32.0341 35.3043 28V25.5652H20.6956V28Z"
      fill="#6DA544"
    />
    <path
      d="M37.7391 23.1304H30.4347C30.4347 21.7858 29.3446 20.6956 27.9999 20.6956C26.6553 20.6956 25.5651 21.7858 25.5651 23.1304H18.2607C18.2607 24.4752 19.432 25.5652 20.7766 25.5652H20.6955C20.6955 26.91 21.7856 28 23.1303 28C23.1303 29.3448 24.2204 30.4348 25.5651 30.4348H30.4347C31.7795 30.4348 32.8695 29.3448 32.8695 28C34.2143 28 35.3043 26.91 35.3043 25.5652H35.2232C36.5679 25.5652 37.7391 24.4752 37.7391 23.1304Z"
      fill="#FF9811"
    />
  </svg>
);

export default SvgMexico;
