import { Navigate, Outlet } from 'react-router-dom';
interface IProtectedRoute {
  isLoggedIn: boolean;
}
const Protected = ({ isLoggedIn }: IProtectedRoute) => {
  const isMobile =
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i);

  if (isMobile) {
    return <Navigate to={'/ismobile'} replace />;
  }
  if (!isLoggedIn) {
    const redirectTo =
      window.location.pathname === '/'
        ? ''
        : `?redirectTo=${window.location.href}`;

    return <Navigate to={`/login${redirectTo}`} replace />;
  }

  return <Outlet />;
};

export default Protected;
