import { IIconProps } from '../interface';

const SvgDenmark = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M21.9132 24.3479H55.763C53.9733 10.6096 42.2261 0 28 0C25.9093 0 23.8731 0.231109 21.9131 0.665547V24.3479H21.9132Z"
      fill="#D80027"
    />
    <path
      d="M14.6087 24.3478V3.40452C6.92042 7.59949 1.41645 15.2941 0.237061 24.3479H14.6087V24.3478Z"
      fill="#D80027"
    />
    <path
      d="M14.6086 31.6521H0.237061C1.41645 40.7059 6.92042 48.4005 14.6087 52.5954L14.6086 31.6521Z"
      fill="#D80027"
    />
    <path
      d="M21.9131 31.6522V55.3345C23.8731 55.7689 25.9093 56 28 56C42.2261 56 53.9733 45.3904 55.763 31.6521H21.9131V31.6522Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgDenmark;
