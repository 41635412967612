import React, { useMemo } from 'react';
import { colors, typography } from '../theme';
import '../theme/fonts/index.css';
import './index.css';
import Tooltip from '../Tooltip';
import reactStringReplace from 'react-string-replace';

export type variantList = keyof typeof typography;

export type colorTypeList = keyof typeof colors;
export type colorBaseList = keyof typeof colors.base;
export type colorNeutralList = keyof typeof colors.neutral;

interface ITypography {
  children: string;
  variant: variantList;
  className?: string;
  divClassName?: string;
  colorType?: colorTypeList;
  color?: colorNeutralList | colorBaseList;
  htmlFor?: string;
  wrap?: boolean;
  clip?: boolean;
  pointer?: boolean;
  lineThough?: boolean;
  onClick?: () => void;
}

const buildTag = (variant: string) => {
  if (variant.search('HeadingDefault') > -1) {
    return 'h1';
  }

  if (variant.search('HeadingSmall') > -1) {
    return 'h2';
  }

  if (variant.search('Label') > -1) {
    return 'label';
  }

  return 'p';
};

const Typography = ({
  children,
  variant,
  className,
  divClassName,
  colorType = 'base',
  color,
  htmlFor,
  wrap,
  clip,
  pointer,
  lineThough,
  onClick,
}: ITypography) => {
  const CustomTag = buildTag(variant);

  const colorBase: { [key: string]: string } = useMemo(
    () => colors[colorType],
    [colorType]
  );

  const typographyColor = color ? colorBase[color] : colors.base.black;

  const escapeHtml = (unsafe: string) => {
    return unsafe.replaceAll('&amp;', '&');
  };

  const handleText = (text: any) => {
    const tag = document.createElement(CustomTag);
    tag.innerHTML = text;

    const tooltips = tag.querySelectorAll('span');
    const links = tag.querySelectorAll('a');
    const clickables = tag.querySelectorAll('u');

    let replacedText = text;

    if (links.length) {
      links.forEach((link) => {
        replacedText = reactStringReplace(
          replacedText,
          escapeHtml(link.outerHTML),
          (_, i) => {
            return (
              <a
                href={link.href}
                target="_blank"
                rel="noreferrer"
                className="text-[#0085FF] underline underline-offset-2"
              >
                {link.innerText}
              </a>
            );
          }
        );
      });
    }
    if (clickables.length) {
      clickables.forEach((clickable, index) => {
        replacedText = reactStringReplace(
          replacedText,
          escapeHtml(clickable.outerHTML),
          (_, i) => {
            return (
              <u className="cursor-pointer" onClick={onClick} key={index}>
                {clickable.innerText}
              </u>
            );
          }
        );
      });
    }

    if (!tooltips.length && !links.length && !clickables.length) {
      return text;
    }

    tooltips.forEach((tooltip) => {
      replacedText = reactStringReplace(
        replacedText,
        `<span data-tooltip="${tooltip.getAttribute('data-tooltip')}">${
          tooltip.innerText
        }</span>`,
        (_, i) => {
          return (
            <Tooltip
              key={i}
              label={tooltip.innerText}
              text={tooltip.getAttribute('data-tooltip')!}
              variant={variant}
            />
          );
        }
      );
    });

    return replacedText;
  };

  return (
    <CustomTag
      style={{ ...typography[variant], color: typographyColor }}
      className={`relative ${className} ${wrap ? 'break-words' : ''} ${
        pointer ? 'cursor-pointer select-none' : 'cursor-default'
      } ${clip ? 'd-block max-w-full' : ''} ${
        lineThough ? 'line-through' : ''
      }`}
      htmlFor={CustomTag === 'label' ? htmlFor : ''}
    >
      <div
        className={`${divClassName} ${
          clip
            ? 'max-w-full whitespace-nowrap overflow-hidden text-ellipsis'
            : ''
        }`}
      >
        {handleText(children)}
      </div>
    </CustomTag>
  );
};

export default Typography;
