import { IIconProps } from '../interface';

const SvgLuxembourg = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28.0001 0C15.9611 0 5.6979 7.5985 1.7417 18.2609H54.2586C50.3023 7.5985 40.0391 0 28.0001 0Z"
      fill="#D80027"
    />
    <path
      d="M28.0001 56C40.0391 56 50.3023 48.4015 54.2585 37.7391H1.7417C5.6979 48.4015 15.9611 56 28.0001 56Z"
      fill="#338AF3"
    />
  </svg>
);

export default SvgLuxembourg;
