import Icon from '../../../design-system/Icon';
import { colors } from '../../../design-system/theme';
import Typography from '../../../design-system/Typography';
import './index.css';

interface IAnchorQuestion {
  label: string;
  action: () => void;
  done: boolean;
  className?: string;
  isActive?: boolean;
}

const AnchorQuestion = ({
  label,
  className,
  action,
  done,
  isActive,
}: IAnchorQuestion) => {
  return (
    <button
      className={`${className} ToolAnchor py-spacing-10 items-center ${
        isActive ? 'bg-neutral-field' : 'bg-white'
      }`}
      onClick={action}
    >
      <Icon
        name={done ? 'Check' : 'Circle'}
        width={8}
        height={8}
        color={colors.neutral.textStrong}
        type={done ? 'sm' : undefined}
      />
      <div
        className={`flex flex-col flex-1 items-start overflow-hidden ml-spacing-16`}
      >
        <Typography variant="LabelSmall500" color="gray600" clip pointer>
          {label}
        </Typography>
      </div>
    </button>
  );
};

export default AnchorQuestion;
