import { IIconProps } from '../interface';

const SvgSwitzerland = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#D80027"
    />
    <path
      d="M42.6087 23.1305H32.8696V13.3913H23.1304V23.1305H13.3914V32.8696H23.1304V42.6087H32.8696V32.8696H42.6087V23.1305Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgSwitzerland;
