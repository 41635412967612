import { IIconProps } from '../interface';

const SvgCanada = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M56 28C56 16.8991 49.5397 7.30734 40.1738 2.77834V53.2215C49.5397 48.6926 56 39.1009 56 28Z"
      fill="#D80027"
    />
    <path
      d="M0 28C0 39.1009 6.46023 48.6926 15.8261 53.2216V2.77834C6.46023 7.30734 0 16.8991 0 28Z"
      fill="#D80027"
    />
    <path
      d="M32.8695 31.6521L37.739 29.2173L35.3043 28V25.5652L30.4347 28L32.8695 23.1304H30.4347L27.9999 19.4783L25.5651 23.1304H23.1303L25.5651 28L20.6956 25.5652V28L18.2609 29.2173L23.1303 31.6521L21.913 34.0869H26.7826V37.7391H29.2173V34.0869H34.0869L32.8695 31.6521Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgCanada;
