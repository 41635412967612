import { IIconProps } from '../interface';

const SvgSingapore = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M0 28.0001C0 12.5361 12.5361 0 28 0C43.4639 0 56 12.5361 56 28.0001"
      fill="#D80027"
    />
    <path
      d="M17.0434 14.6086C17.0434 10.5291 19.9104 7.12119 23.739 6.28502C23.1505 6.15662 22.5401 6.08694 21.9128 6.08694C17.2063 6.08694 13.3911 9.90216 13.3911 14.6087C13.3911 19.3152 17.2063 23.1304 21.9128 23.1304C22.5399 23.1304 23.1504 23.0607 23.739 22.9322C19.9104 22.096 17.0434 18.6881 17.0434 14.6086Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 6.69562L28.6044 8.55554H30.5601L28.9778 9.70518L29.5822 11.5651L28 10.4157L26.4178 11.5651L27.0222 9.70518L25.4399 8.55554H27.3956L28 6.69562Z"
      fill="#F0F0F0"
    />
    <path
      d="M23.2558 10.3478L23.8601 12.2078H25.8158L24.2336 13.3573L24.838 15.2172L23.2558 14.0678L21.6734 15.2172L22.2779 13.3573L20.6956 12.2078H22.6513L23.2558 10.3478Z"
      fill="#F0F0F0"
    />
    <path
      d="M32.7441 10.3478L33.3486 12.2078H35.3043L33.7219 13.3573L34.3264 15.2172L32.7441 14.0678L31.1619 15.2172L31.7663 13.3573L30.1841 12.2078H32.1397L32.7441 10.3478Z"
      fill="#F0F0F0"
    />
    <path
      d="M30.9182 15.826L31.5226 17.686H33.4782L31.896 18.8356L32.5004 20.6955L30.9182 19.5461L29.336 20.6955L29.9404 18.8356L28.3582 17.686H30.3137L30.9182 15.826Z"
      fill="#F0F0F0"
    />
    <path
      d="M25.0819 15.826L25.6862 17.686H27.642L26.0597 18.8356L26.6642 20.6955L25.0819 19.5461L23.4995 20.6955L24.1039 18.8356L22.5217 17.686H24.4775L25.0819 15.826Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgSingapore;
