import { FlagsListInitials } from '../design-system/Icon/flags/enum';
import { IAnchor } from '../design-system/Anchor';
import { IToolAnchor } from './../design-system/Tool/Anchor';
import { IAnswer } from '../flows/questionsFlows/defaultQuestions';

export interface IToolAnchorMenu extends IToolAnchor {
  questions: IToolAnchor[];
  opened?: boolean;
}

export const sidebarAppAnchors: IAnchor[] = [
  {
    label: 'Home',
    icon: 'Home',
    path: '/',
  },
  {
    label: 'Inbox',
    icon: 'Message',
    path: '/inbox',
  },
  {
    label: 'Filings',
    icon: 'Document',
    path: '/filings',
  },
  {
    label: 'Statistics',
    icon: 'Barchart',
    path: '/statistics',
  },
];

export const defaultCountries: IAnswer[] = [
  {
    id: 'BR',
    label: `countries.BR`,
    icon: FlagsListInitials['BR'],
  },
  {
    id: 'US',
    label: `countries.US`,
    icon: FlagsListInitials['US'],
  },
  {
    id: 'NL',
    label: `countries.NL`,
    icon: FlagsListInitials['NL'],
  },
  {
    id: 'PT',
    label: `countries.PT`,
    icon: FlagsListInitials['PT'],
  },
  {
    id: 'FR',
    label: `countries.FR`,
    icon: FlagsListInitials['FR'],
  },
  {
    id: 'ES',
    label: `countries.ES`,
    icon: FlagsListInitials['ES'],
  },
  {
    id: 'DE',
    label: `countries.DE`,
    icon: FlagsListInitials['DE'],
  },
  {
    id: 'IN',
    label: `countries.IN`,
    icon: FlagsListInitials['IN'],
  },
  {
    id: 'CA',
    label: `countries.CA`,
    icon: FlagsListInitials['CA'],
  },
  {
    id: 'CN',
    label: `countries.CN`,
    icon: FlagsListInitials['CN'],
  },
  {
    id: 'MX',
    label: `countries.MX`,
    icon: FlagsListInitials['MX'],
  },
  {
    id: 'AU',
    label: `countries.AU`,
    icon: FlagsListInitials['AU'],
  },
];
