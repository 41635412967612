import { createSlice } from '@reduxjs/toolkit';
import { defaultCountries } from '../components/consts';
import {
  DEFAULT_COUNTRIES_LIMIT,
  fiscalYears,
  ICurrentQuestion,
  initialCurrentQuestionState,
} from '../consts';
import { FlagsListInitials } from '../design-system/Icon/flags/enum';
import {
  defaultQuestions,
  defaultQuestionsCountry,
  firstCategories,
  lastCategories,
} from '../flows';
import { IAnswer } from '../flows/questionsFlows/defaultQuestions';
import { buildByYearToSideBar } from '../utils/yearUtils';

interface IAnswerValue {
  id: string;
  value: any;
}
export interface IStateTrt {
  roadmap: ICurrentQuestion[];
  currentQuestion: ICurrentQuestion;
  countryAnswers: IAnswer[];
  fiscalYear: string;
  countries: string[];
  finished: boolean;
}

const initialState: IStateTrt = {
  roadmap: [],
  currentQuestion: initialCurrentQuestionState,
  countryAnswers: [],
  fiscalYear: '',
  countries: [],
  finished: false,
};

const getNextQuestion = (state: IStateTrt) => {
  const nextQuestion = state.roadmap.find((question) => !question.done);
  return nextQuestion || initialCurrentQuestionState;
};

const storeCurrentState = (state: IStateTrt) => {
  localStorage.setItem('roadmap', JSON.stringify(state.roadmap));
};

export const getCurrentQuestionRoadmapIndex = (
  state: IStateTrt,
  roadmap?: ICurrentQuestion
) => {
  return state.roadmap.findIndex(
    (x) => x.id === roadmap?.id && x.category_id === roadmap.category_id
  );
};

export const trtSlice = createSlice({
  name: 'trtool',
  initialState,
  reducers: {
    addNewQuestion: (state, action) => {
      const currentQuestionRoadmapIndex = getCurrentQuestionRoadmapIndex(
        state,
        state.currentQuestion
      );

      if (state.roadmap.find((q) => q.id === action.payload)) return;

      state.roadmap.splice(currentQuestionRoadmapIndex + 1, 0, {
        category_id: state.currentQuestion.category_id || '',
        id: action.payload,
        done: false,
        aditionalQuestion: true,
        answers: [],
        ignore: false,
      });
      storeCurrentState(state);
    },
    loadCountryAnswers: (state) => {
      const returnArr: IAnswer[] = [];

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, browserCountryInitials] = Intl.DateTimeFormat()
        .resolvedOptions()
        .locale.split('-');

      state.countries.forEach((initials) => {
        returnArr.push({
          id: initials,
          label: `countries.${initials}`,
          icon: FlagsListInitials[initials],
          key: '',
          value: '',
        });
      });

      returnArr.forEach((c) => {
        const indexToRemove = defaultCountries.findIndex((x) => x.id === c.id);
        if (indexToRemove > -1) {
          defaultCountries.splice(indexToRemove, 1);
        }
      });

      defaultCountries.slice(0, DEFAULT_COUNTRIES_LIMIT).forEach((country) => {
        returnArr.push(country);
      });

      if (!returnArr.find((x) => x.id === browserCountryInitials)) {
        returnArr.push({
          id: browserCountryInitials,
          label: `countries.${browserCountryInitials}`,
          icon: FlagsListInitials[browserCountryInitials],
        });
      }

      state.countryAnswers = returnArr;
    },
    addCountryToAnswerList: (state, action) => {
      const countryToAdd = action.payload;
      const countryAlreadyIn = state.countryAnswers.find(
        (x) => x.id === countryToAdd.id
      );
      if (!countryAlreadyIn) {
        state.countryAnswers.push(countryToAdd);
      }
    },
    setRoadmap: (state, action) => {
      state.roadmap = action.payload;

      state.currentQuestion = getNextQuestion(state);
    },
    setReloadRoadMapState: (state) => {
      const roadmap = state.roadmap.find(
        (question) =>
          question.id === state.currentQuestion.id &&
          question.category_id === state.currentQuestion.category_id
      );

      const currentQuestionRoadmapIndex = getCurrentQuestionRoadmapIndex(
        state,
        roadmap
      );

      const persistAdditionQuestions = [] as string[];

      state.roadmap = state.roadmap.map((item, index) => {
        if (index < currentQuestionRoadmapIndex) {
          defaultQuestions[item.id].answers.forEach((answer) => {
            if (item.answers.includes(answer.id) && answer.aditionalQuestions) {
              answer.aditionalQuestions.forEach((item) =>
                persistAdditionQuestions.push(item)
              );
            }
          });
        }

        return {
          ...item,
          done: currentQuestionRoadmapIndex > index,
          answers: currentQuestionRoadmapIndex > index ? item.answers : [],
          ignore: currentQuestionRoadmapIndex > index ? item.ignore : false,
        };
      });

      state.roadmap = state.roadmap.filter((item, index) => {
        if (
          index > currentQuestionRoadmapIndex &&
          persistAdditionQuestions.includes(item.id)
        ) {
          return true;
        }

        if (index > currentQuestionRoadmapIndex && item.aditionalQuestion) {
          return false;
        }

        return true;
      });
    },
    setAnswerQuestion: (state, action) => {
      const countries = localStorage.getItem('countries');

      const answers: IAnswerValue[] = action.payload;
      answers.forEach((answer) => {
        if (
          FlagsListInitials[answer.id.toUpperCase()] &&
          countries?.search(answer.id) === -1
        ) {
          const countriesAnswers = countries
            ? `${countries},${answer.id.toUpperCase()}`
            : answer.id.toUpperCase();

          localStorage.setItem('countries', countriesAnswers);
        }
      });

      const roadmap = state.roadmap.find(
        (question) =>
          question.id === state.currentQuestion.id &&
          question.category_id === state.currentQuestion.category_id
      );

      const currentQuestionRoadmapIndex = getCurrentQuestionRoadmapIndex(
        state,
        roadmap
      );

      const currenQuestionPayload = defaultQuestions[state.currentQuestion.id];

      const aditionalQuestions = currenQuestionPayload.answers.reduce(
        (acc, crr) => {
          if (
            crr?.aditionalQuestions &&
            answers.filter((a) => a.id === crr.id).length > 0
          ) {
            return [...acc, ...crr?.aditionalQuestions];
          }

          return acc;
        },
        [] as string[]
      );

      if (aditionalQuestions.length) {
        aditionalQuestions.reverse();
        aditionalQuestions.forEach((question) => {
          state.roadmap.splice(currentQuestionRoadmapIndex + 1, 0, {
            category_id: state.currentQuestion.category_id || '',
            id: question,
            done: false,
            ignore: false,
            aditionalQuestion: true,
            answers: [],
          });
        });
      }

      if (roadmap) {
        roadmap.answers = answers.map((a) => a['id']);
        roadmap.answersValues = answers.map((a) => a['value']);
        roadmap.done = true;
      }

      if (
        !getNextQuestion(state).id ||
        state.currentQuestion.id === 'tax_residence_known_confirmation'
      ) {
        state.finished = true;
        state.roadmap.forEach((question) => {
          if (
            question.id !== 'tax_residence_known' &&
            question.id !== 'tax_residence_select_country' &&
            question.id !== 'tax_residence_fiscal_country'
          ) {
            question.done = true;
            question.ignore = true;
          } else {
            question.ignore = false;
          }
        });
      }

      state.currentQuestion = getNextQuestion(state);
      storeCurrentState(state);
    },
    changeQuestionById: (state, action) => {
      const { category_id, question_id } = action.payload;

      const question = state.roadmap.find(
        (question) =>
          question.category_id === category_id && question.id === question_id
      );

      if (question) {
        state.currentQuestion = question;
      }
    },
    skipQuestion: (state, action) => {
      state.roadmap.forEach((roadmap) => {
        if (
          roadmap.id === action.payload.id &&
          roadmap.category_id === action.payload.category
        ) {
          roadmap.ignore = action.payload.ignore;
          roadmap.done = true;
          if (action.payload.answer) {
            if (typeof action.payload.answer !== 'object') {
              roadmap.answers = [action.payload.answer];
            } else {
              roadmap.answers = action.payload.answer;
            }
          }
          state.currentQuestion = getNextQuestion(state);
        }
      });
      storeCurrentState(state);
    },
    setFiscalYear: (state, action) => {
      localStorage.setItem('year', action.payload);
      state.fiscalYear = action.payload;
    },
    setCountries: (state, action) => {
      localStorage.setItem('countries', action.payload);
      const countriesARR = action.payload
        .split(',')
        .filter((c: string) => c.length);

      state.countries = countriesARR;
    },
    buildRoadmapCountries: (
      state,
      action: { payload: { fy: string; country: string }[] }
    ) => {
      // Check previous answers
      const countryAnswers = state.roadmap
        .filter((question) => question.category_id === 'category_1')
        .map((x) => x.answers)
        .flat()
        .filter((answer) => FlagsListInitials[answer]);

      const countries: string[] = [];
      countryAnswers.forEach((country) => {
        if (!countries.find((c) => c === country)) {
          countries.push(country);
        }
      });

      state.countries = countries;

      const countriesWithYear = state.countries.reduce((acc, country) => {
        if (fiscalYears[country]) {
          const ignore = action.payload.reduce((acc, crr) => {
            if (crr.country === country) {
              return [...acc, crr.fy];
            }

            return [...acc];
          }, [] as string[]);

          const buildFiscarYears = buildByYearToSideBar({
            fiscalYear: state.fiscalYear,
          });

          const fiscalYears = buildFiscarYears.reduce((acc, crr) => {
            if (ignore.includes(crr)) {
              return [...acc];
            }

            return [...acc, crr];
          }, [] as string[]);

          const fiscal = fiscalYears.map(
            (fiscalYear) =>
              ({
                country,
                fiscalYear,
              } as {
                country: string;
                fiscalYear: string;
              })
          );

          return [...acc, ...fiscal];
        }

        return [...acc, { country }];
      }, [] as { fiscalYear?: string; country: string }[]);

      const firstCategoriesItems = firstCategories.map(
        (category) => category.category_id
      );

      const lastCategoriesItems = lastCategories.map(
        (category) => category.category_id
      );

      const roadmapWithoutLastCategories = state.roadmap.filter((roadmap) =>
        firstCategoriesItems.includes(roadmap.category_id)
      );

      const roadmapWithtLastCategories = state.roadmap.filter((roadmap) =>
        lastCategoriesItems.includes(roadmap.category_id)
      );

      const roadmapCountries =
        countriesWithYear?.reduce((accumlator, { country, fiscalYear }) => {
          const countriesQuestions = defaultQuestionsCountry.reduce(
            (acc, crr) => {
              const { id } = defaultQuestions[crr];

              const category_id = fiscalYear
                ? `${country}-${fiscalYear.replace('/', '')}`
                : country;

              return [
                ...acc,
                {
                  id,
                  category_id,
                  fiscalYear,
                  initial: country,
                  done: false,
                  ignore: false,
                  aditionalQuestion: false,
                  answers: [],
                },
              ];
            },
            [] as ICurrentQuestion[]
          );

          return [...accumlator, ...countriesQuestions];
        }, [] as ICurrentQuestion[]) || [];

      state.roadmap = [
        ...roadmapWithoutLastCategories,
        ...roadmapCountries,
        ...roadmapWithtLastCategories,
      ];
    },
    clearState: (state) => {
      state.finished = initialState.finished;
      state.countries = initialState.countries;
      state.countryAnswers = initialState.countryAnswers;
      state.currentQuestion = initialState.currentQuestion;
      state.fiscalYear = initialState.fiscalYear;
      state.roadmap = initialState.roadmap;
      localStorage.removeItem('countries');
      localStorage.removeItem('year');
      localStorage.removeItem('roadmap');
    },
    setFinished: (state, action) => {
      state.finished = action.payload;
    },
  },
});

export const {
  setAnswerQuestion,
  setRoadmap,
  addCountryToAnswerList,
  setReloadRoadMapState,
  changeQuestionById,
  loadCountryAnswers,
  addNewQuestion,
  skipQuestion,
  setFiscalYear,
  setCountries,
  buildRoadmapCountries,
  clearState,
  setFinished,
} = trtSlice.actions;

export default trtSlice.reducer;
