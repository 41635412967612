import * as VMasker from 'vanilla-masker';

export function formatToDecimalValue(value: string | number) {
  const number = formatToNumber(value);
  return parseFloat((parseInt(number) / 100).toFixed(2));
}

export function formatToNumber(value: string | number) {
  return VMasker.toNumber(value ?? '');
}

export function formatToPattern(value: string | number, format: string) {
  return VMasker.toPattern(value ?? '', format);
}

export const getTwoInitialLetters = (text: string) => {
  const splitedText = text.split(' ');

  if (splitedText.length > 1) {
    return [splitedText[0].slice(0, 1), splitedText[1].slice(0, 1)].join('');
  }

  return text.slice(0, 2);
};
