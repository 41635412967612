import { SpinnerLoading } from '../../design-system';

const Loading = ({ variant }: { variant: 'FULL' | 'APP' }) => {
  const styles = variant === 'APP' ? { width: 'calc(100% - 270px)' } : {};
  return (
    <div
      className="flex fixed z-10 w-full h-full items-center justify-center bg-base-gray25"
      style={styles}
    >
      <SpinnerLoading
        variant="secondary"
        active={true}
        width={60}
        height={60}
      />
    </div>
  );
};

export default Loading;
