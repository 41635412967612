const spacing = {
  'spacing-0': '0rem',
  'spacing-1': '1px',
  'spacing-2': '2px',
  'spacing-4': '0.25rem',
  'spacing-5': '0.313rem',
  'spacing-6': '0.375rem',
  'spacing-8': '0.5rem',
  'spacing-10': '0.625rem',
  'spacing-12': '0.75rem',
  'spacing-14': '0.875rem',
  'spacing-16': '1rem',
  'spacing-18': '1.125rem',
  'spacing-20': '1.25rem',
  'spacing-24': '1.5rem',
  'spacing-26': '1.625rem',
  'spacing-28': '1.75rem',
  'spacing-32': '2rem',
  'spacing-36': '2.25rem',
  'spacing-40': '2.5rem',
  'spacing-44': '2.75rem',
  'spacing-48': '3rem',
  'spacing-54': '3.375rem',
  'spacing-56': '3.5rem',
  'spacing-64': '4rem',
  'spacing-66': '4.125rem',
  'spacing-72': '4.5rem',
  'spacing-80': '5rem',
  'spacing-90': '5.625rem',
  'spacing-100': '6.25rem',
  'spacing-110': '6.875rem',
  'spacing-128': '8rem',
  'spacing-140': '8.75rem',
  'spacing-158': '9.875rem',
  'spacing-188': '11.75rem',
  'spacing-192': '12rem',
  'spacing-195': '12.188rem',
  'spacing-210': '13.125rem',
  'spacing-270': '16.875rem',
  'spacing-340': '21.25rem',
  'spacing-400': '25rem',
  'spacing-500': '31.25rem',
  'spacing-560': '35rem',
  'spacing-610': '38.125rem',
  'spacing-640': '40rem',
  'spacing-660': '41.25rem',
  'spacing-824': '51.5rem',
  'spacing-726': '45.375rem',
  'spacing-848': '53rem',
  'spacing-1080': '67.5rem',
};

export default spacing;
