import { changeLanguage } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Icon } from '../../design-system';
import { languanges } from '../../languages';
import { addBreadcrumb, AppsCategoryEnum, Level } from '../../utils/sentry';
import Collapse from '../collapse';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem('language') || 'en'
  );

  const handleLangChange = () => {
    localStorage.setItem('language', currentLang);
    switch (currentLang) {
      case 'en':
        return <Icon width={24} height={24} name="UnitedStates" />;
      case 'pt':
        return <Icon width={24} height={24} name="Brazil" />;
      case 'es':
        return <Icon width={24} height={24} name="Spain" />;
      default:
        break;
    }
  };
  return (
    <Collapse
      className="mr-spacing-10 z-10 relative"
      header={
        <Grid noPadding fluid className="justify-center cursor-pointer">
          <Grid
            noPadding
            fluid
            className="flex items-center bg-neutral-field hover:bg-neutral-fieldHover 
                  active:bg-neutral-fieldActive px-spacing-10 py-spacing-6 rounded-md cursor-pointer"
          >
            {handleLangChange() || <></>}
            <Grid noPadding fluid className="ml-spacing-8 flex-1">
              <Icon width={16} height={16} name="ChevronDown" />
            </Grid>
          </Grid>
        </Grid>
      }
    >
      <div className="absolute right-0">
        <Grid
          noPadding
          fluid
          className="bg-neutral-field px-spacing-10 py-spacing-6 rounded-md mt-spacing-8 flex-col"
        >
          {languanges.map((lang, index) => (
            <Grid
              key={index}
              noPadding
              fluid
              onClick={() => {
                changeLanguage(lang.initials);
                setCurrentLang(i18n.language);

                addBreadcrumb({
                  category: AppsCategoryEnum.TOOL,
                  message: 'Changing language',
                  level: Level.Info,
                  data: { languange: i18n.language },
                });
              }}
              className="hover:bg-neutral-fieldHover active:bg-neutral-fieldActive cursor-pointer"
            >
              <Typography variant="BodyDefault400" pointer>
                {lang.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
    </Collapse>
  );
};

export default LanguageSelector;
