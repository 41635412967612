import { IIconProps } from '../interface';

const SvgHungary = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.74158 18.2609C0.616109 21.2943 0 24.575 0 28C0 31.425 0.616109 34.7059 1.74158 37.7391L28 40.1739L54.2584 37.7391C55.3839 34.7059 56 31.425 56 28C56 24.575 55.3839 21.2943 54.2584 18.2609L28 15.8261L1.74158 18.2609Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 0C15.9611 0 5.69779 7.5985 1.7417 18.2609H54.2586C50.3022 7.5985 40.0391 0 28 0Z"
      fill="#D80027"
    />
    <path
      d="M28 56C40.0391 56 50.3022 48.4015 54.2585 37.7391H1.7417C5.69779 48.4015 15.9611 56 28 56Z"
      fill="#6DA544"
    />
  </svg>
);

export default SvgHungary;
