import { useEffect, useState, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes as ListRoutes,
} from 'react-router-dom';
import { User } from '@supabase/supabase-js';
import { supabase } from './supabaseClient';
import AppLayout from './components/layouts/AppLayout';
import Protected from './components/ProtectedRoute';
import SuspenseLoading from './components/suspenseLoading';
import ReactGA from 'react-ga4';
import AppGoBack from './components/layouts/AppGoBack';
import getUrlParameters from './utils/getUrlParameters';
import NoMatch from './screens/NoMatch';

const Home = lazy(() => import('./screens/AppScreens/Home'));
const Login = lazy(() => import('./screens/AppScreens/Authentication/Login'));
const Register = lazy(
  () => import('./screens/AppScreens/Authentication/Register')
);
const ForgotPassword = lazy(
  () => import('./screens/AppScreens/Authentication/ForgotPassword')
);
const IsMobile = lazy(() => import('./screens/AppScreens/IsMobile'));
const ResetPassword = lazy(
  () => import('./screens/AppScreens/Authentication/ResetPassword')
);
const ToolQuestions = lazy(
  () => import('./screens/ToolScreens/screens/Questions')
);
const Checkout = lazy(
  () => import('./screens/AppScreens/SubmitPayment/Checkout')
);
const OutputDetail = lazy(
  () => import('./screens/ToolScreens/screens/OutputDetail')
);
const Output = lazy(() => import('./screens/ToolScreens/screens/Output'));

const TaxYear = lazy(() => import('./screens/AppScreens/TaxYear'));

const Verify = lazy(() => import('./screens/AppScreens/Verify'));

const CallUs = lazy(() => import('./screens/AppScreens/Verify/callUs'));

const SelectCountry = lazy(
  () => import('./screens/AppScreens/Verify/selectCountry')
);

const TaxResidenceKnown = lazy(
  () => import('./screens/ToolScreens/screens/TaxResidenceKnown')
);
const TaxAllocation = lazy(() => import('./screens/AppScreens/TaxAllocation'));

const IncomeQuestion = lazy(
  () => import('./screens/AppScreens/IncomeQuestion')
);

const IncomeCategories = lazy(
  () => import('./screens/AppScreens/IncomeCategories')
);

const SelectCountries = lazy(
  () => import('./screens/AppScreens/SelectCountries')
);

const AdditionalComplexities = lazy(
  () => import('./screens/AppScreens/AdditionalComplexities')
);

const Payment = lazy(() => import('./screens/AppScreens/Payment'));

const PaymentRefresh = lazy(
  () => import('./screens/AppScreens/PaymentRefresh')
);

const PaymentSuccess = lazy(
  () => import('./screens/AppScreens/PaymentSuccess')
);

const PaymentSummary = lazy(
  () => import('./screens/AppScreens/SubmitPayment/Summary')
);

const VerifyWithUs = lazy(() => import('./screens/AppScreens/VerifyWithUs'));

const VerifyWithUsSuccess = lazy(
  () => import('./screens/AppScreens/VerifyWithUsSuccess')
);

const Routes = () => {
  const [user, setUser] = useState<User | null>({} as User);

  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    const aid = getUrlParameters('aid');
    if (aid) {
      localStorage.setItem('aid', aid);
    }
    const handleSession = async () => {
      const sessionData = await supabase.auth.getSession();
      setUser(sessionData?.data.session?.user ?? null);

      const { data: authListener } = supabase.auth.onAuthStateChange(
        async (_, session) => {
          const currentUser = session?.user;
          setUser(currentUser ?? null);

          const redirectTo = getUrlParameters('redirectTo');

          if (currentUser && window.location.pathname.search(/login/gi) > -1) {
            if (redirectTo) {
              window.location.href = redirectTo;
              return;
            }

            window.location.href = '/';
          }
        }
      );

      return () => {
        authListener.subscription.unsubscribe();
      };
    };

    handleSession();
  }, []);

  return (
    <Router>
      <ListRoutes>
        <Route
          path="/register"
          element={
            <SuspenseLoading variant="FULL">
              <Register />
            </SuspenseLoading>
          }
        />
        <Route
          path="*"
          element={
            <SuspenseLoading variant="FULL">
              <NoMatch />
            </SuspenseLoading>
          }
        />
        <Route
          path="/forgot_password"
          element={
            <SuspenseLoading variant="FULL">
              <ForgotPassword />
            </SuspenseLoading>
          }
        />
        <Route
          path="/reset_password"
          element={
            <SuspenseLoading variant="FULL">
              <ResetPassword />
            </SuspenseLoading>
          }
        />
        <Route
          path="/trt"
          element={
            <SuspenseLoading variant="FULL">
              <ToolQuestions />
            </SuspenseLoading>
          }
        />
        <Route
          path="/output"
          element={
            <SuspenseLoading variant="FULL">
              <Output />
            </SuspenseLoading>
          }
        />
        <Route element={<AppGoBack />}>
          <Route
            path="/ismobile"
            element={
              <SuspenseLoading variant="FULL">
                <IsMobile />
              </SuspenseLoading>
            }
          />
          <Route
            path="/output/taxresidenceknown"
            element={
              <SuspenseLoading variant="FULL">
                <TaxResidenceKnown />
              </SuspenseLoading>
            }
          />
        </Route>
        <Route
          path="/output/detail"
          element={
            <SuspenseLoading variant="FULL">
              <OutputDetail />
            </SuspenseLoading>
          }
        />

        <Route
          path="/login"
          element={
            <SuspenseLoading variant="FULL">
              <Login />
            </SuspenseLoading>
          }
        />
        <Route
          path="/stripeconnect/success"
          element={
            <SuspenseLoading variant="FULL">
              <PaymentSuccess />
            </SuspenseLoading>
          }
        />
        <Route element={<AppGoBack />}>
          <Route
            path="/schedulesuccess"
            element={
              <SuspenseLoading variant="FULL">
                <VerifyWithUsSuccess />
              </SuspenseLoading>
            }
          />
        </Route>
        <Route element={<Protected isLoggedIn={user !== null} />}>
          <Route element={<AppLayout />}>
            <Route
              path="/"
              element={
                <SuspenseLoading variant="FULL">
                  <Home />
                </SuspenseLoading>
              }
            />
          </Route>
          <Route
            path="/taxallocation/questions/:tatId/:tatItemId/:categoryKey/:calendaryearid"
            element={
              <SuspenseLoading variant="FULL">
                <IncomeQuestion />
              </SuspenseLoading>
            }
          />
          <Route element={<AppGoBack />}>
            <Route
              path="/taxyear"
              element={
                <SuspenseLoading variant="FULL">
                  <TaxYear />
                </SuspenseLoading>
              }
            />
            <Route
              path="/stripeconnect"
              element={
                <SuspenseLoading variant="FULL">
                  <Payment />
                </SuspenseLoading>
              }
            />
            <Route
              path="/stripeconnect/refresh"
              element={
                <SuspenseLoading variant="FULL">
                  <PaymentRefresh />
                </SuspenseLoading>
              }
            />
            <Route
              path="/payment/checkout"
              element={
                <SuspenseLoading variant="APP">
                  <Checkout />
                </SuspenseLoading>
              }
            />
            <Route
              path="/verify"
              element={
                <SuspenseLoading variant="FULL">
                  <Verify />
                </SuspenseLoading>
              }
            />
            <Route
              path="/verify/call-us"
              element={
                <SuspenseLoading variant="FULL">
                  <CallUs />
                </SuspenseLoading>
              }
            />
            <Route
              path="/verify/select-country"
              element={
                <SuspenseLoading variant="FULL">
                  <SelectCountry />
                </SuspenseLoading>
              }
            />
            <Route
              path="/taxyear/select-countries"
              element={
                <SuspenseLoading variant="FULL">
                  <SelectCountries />
                </SuspenseLoading>
              }
            />
            <Route
              path="/taxallocation"
              element={
                <SuspenseLoading variant="FULL">
                  <TaxAllocation />
                </SuspenseLoading>
              }
            />
            <Route
              path="/taxallocation/categories"
              element={
                <SuspenseLoading variant="FULL">
                  <IncomeCategories />
                </SuspenseLoading>
              }
            />
            <Route
              path="/additionalcomplexities"
              element={
                <SuspenseLoading variant="FULL">
                  <AdditionalComplexities />
                </SuspenseLoading>
              }
            />
            <Route
              path="/verify-with-us"
              element={
                <SuspenseLoading variant="FULL">
                  <VerifyWithUs />
                </SuspenseLoading>
              }
            />
            <Route
              path="/payment/summary"
              element={
                <SuspenseLoading variant="FULL">
                  <PaymentSummary />
                </SuspenseLoading>
              }
            />
          </Route>
        </Route>
      </ListRoutes>
    </Router>
  );
};

export default Routes;
