import { IIconProps } from '../interface';

const SvgBulgaria = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 24.575 55.384 21.2941 54.2584 18.2609L28 17.0435L1.74158 18.2608C0.616109 21.2941 0 24.575 0 28C0 31.425 0.616109 34.7059 1.74158 37.7391L28 38.9565L54.2584 37.7392C55.384 34.7059 56 31.425 56 28Z"
      fill="#496E2D"
    />
    <path
      d="M28 56C40.039 56 50.3022 48.4015 54.2584 37.7391H1.74158C5.69778 48.4015 15.961 56 28 56Z"
      fill="#D80027"
    />
    <path
      d="M1.74158 18.2609H54.2585C50.3022 7.5985 40.039 0 28 0C15.961 0 5.69778 7.5985 1.74158 18.2609Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgBulgaria;
