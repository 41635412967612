import { IIconProps } from '../interface';

const SvgUnitedKingdom = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16923)">
      <path
        d="M28 56.418C43.464 56.418 56 43.8819 56 28.418C56 12.954 43.464 0.417969 28 0.417969C12.536 0.417969 0 12.954 0 28.418C0 43.8819 12.536 56.418 28 56.418Z"
        fill="#F0F0F0"
      />
      <path
        d="M5.78815 11.371C3.58872 14.2326 1.93027 17.5308 0.9646 21.1138H15.5309L5.78815 11.371Z"
        fill="#0052B4"
      />
      <path
        d="M55.0354 21.1138C54.0697 17.5309 52.4111 14.2327 50.2118 11.3712L40.4692 21.1138H55.0354Z"
        fill="#0052B4"
      />
      <path
        d="M0.9646 35.7225C1.93038 39.3054 3.58883 42.6036 5.78815 45.4651L15.5306 35.7225H0.9646Z"
        fill="#0052B4"
      />
      <path
        d="M45.0469 6.20622C42.1853 4.0068 38.8872 2.34835 35.3042 1.38257V15.9488L45.0469 6.20622Z"
        fill="#0052B4"
      />
      <path
        d="M10.9531 50.6297C13.8147 52.8292 17.1129 54.4876 20.6958 55.4534V40.8873L10.9531 50.6297Z"
        fill="#0052B4"
      />
      <path
        d="M20.6957 1.38257C17.1128 2.34835 13.8146 4.0068 10.9531 6.20611L20.6957 15.9487V1.38257Z"
        fill="#0052B4"
      />
      <path
        d="M35.3044 55.4534C38.8873 54.4876 42.1856 52.8292 45.047 50.6298L35.3044 40.8873V55.4534Z"
        fill="#0052B4"
      />
      <path
        d="M40.4692 35.7225L50.2118 45.4652C52.4111 42.6037 54.0697 39.3054 55.0354 35.7225H40.4692Z"
        fill="#0052B4"
      />
      <path
        d="M55.763 24.7658H31.6524H31.6523V0.654984C30.4567 0.499344 29.2378 0.417969 28 0.417969C26.762 0.417969 25.5433 0.499344 24.3479 0.654984V24.7656V24.7657H0.237016C0.081375 25.9613 0 27.1802 0 28.418C0 29.656 0.081375 30.8746 0.237016 32.0701H24.3476H24.3477V56.181C25.5433 56.3366 26.762 56.418 28 56.418C29.2378 56.418 30.4567 56.3367 31.6521 56.181V32.0703V32.0702H55.763C55.9186 30.8746 56 29.656 56 28.418C56 27.1802 55.9186 25.9613 55.763 24.7658Z"
        fill="#D80027"
      />
      <path
        d="M35.3044 35.7225L47.799 48.217C48.3737 47.6426 48.9219 47.042 49.4449 46.4194L38.7478 35.7224H35.3044V35.7225Z"
        fill="#D80027"
      />
      <path
        d="M20.6956 35.7225H20.6954L8.20093 48.2169C8.77537 48.7916 9.37594 49.3398 9.99851 49.8628L20.6956 39.1655V35.7225Z"
        fill="#D80027"
      />
      <path
        d="M20.6956 21.1138V21.1136L8.20105 8.6189C7.62639 9.19333 7.07821 9.79391 6.55518 10.4165L17.2524 21.1137H20.6956V21.1138Z"
        fill="#D80027"
      />
      <path
        d="M35.3044 21.1138L47.7991 8.61903C47.2247 8.04438 46.6241 7.49619 46.0015 6.97327L35.3044 17.6705V21.1138Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16923">
        <rect
          width="56"
          height="57"
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgUnitedKingdom;
