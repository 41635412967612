import { configureStore } from '@reduxjs/toolkit';

import trtoolReducer, { IStateTrt } from './reducers/trtReducer';
import tatoolReducer, { IStateTat } from './reducers/tatReducer';
import headerReducer, { IStateHeader } from './reducers/headerReducer';

export interface IRootState {
  trtReducer: IStateTrt;
  tatReducer: IStateTat;
  headerReducer: IStateHeader;
}

export default configureStore({
  reducer: {
    trtReducer: trtoolReducer,
    tatReducer: tatoolReducer,
    headerReducer: headerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
