import { IIconProps } from '../interface';

const SvgInvoiceTextList = ({
  width = 32,
  height = 32,
  color,
  type,
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1702_11981)">
        <path
          d="M5.33301 6.80827V26.1449C5.33301 26.3921 5.40171 26.6344 5.53144 26.8447C5.66118 27.0551 5.84683 27.2253 6.06767 27.3363L7.38767 27.9996C7.57215 28.0923 7.77562 28.1409 7.98208 28.1416C8.18853 28.1423 8.39232 28.095 8.57738 28.0035L10.674 26.9667L12.7286 27.9988C12.9136 28.0917 13.1176 28.1403 13.3247 28.1407C13.5317 28.141 13.736 28.0932 13.9213 28.0009L15.9997 26.9663L18.078 28.0009C18.2634 28.0932 18.4676 28.141 18.6746 28.1407C18.8817 28.1403 19.0858 28.0917 19.2708 27.9988L21.3253 26.9667L23.4219 28.0035C23.607 28.095 23.8108 28.1423 24.0172 28.1416C24.2237 28.1409 24.4272 28.0923 24.6116 27.9996L25.9316 27.3363C26.1525 27.2253 26.3382 27.0551 26.4679 26.8447C26.5976 26.6344 26.6663 26.3921 26.6663 26.1449V6.80827C26.6663 6.10102 26.3854 5.42275 25.8853 4.92265C25.3852 4.42255 24.7069 4.1416 23.9997 4.1416H7.99967C7.29243 4.1416 6.61415 4.42255 6.11406 4.92265C5.61396 5.42275 5.33301 6.10102 5.33301 6.80827V6.80827Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3337 10.8081H10.667"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.667 15.4751H21.3337"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.667 20.1416H21.3337"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1702_11981">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 0.141602)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgInvoiceTextList;
