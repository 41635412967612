import React from 'react';
import Icon from '../../../design-system/Icon';
import { colors } from '../../../design-system/theme';
import Typography from '../../../design-system/Typography';
import { IconsList } from '../../Icon/icons/enum';
import './index.css';

interface IAnchorCategory {
  label: string;
  labelYear?: string;
  icon: IconsList;
  done: boolean;
  disabled: boolean;
  className?: string;
}

const AnchorCategory = ({
  label,
  labelYear,
  icon,
  className,
  done,
  disabled,
}: IAnchorCategory) => {
  return (
    <button
      className={`${className} ${disabled && 'pointer-events-none'} ${
        !done && !disabled ? 'py-spacing-10' : 'py-spacing-8'
      } ToolAnchor`}
    >
      <Icon
        name={done ? 'Check' : icon}
        width={14}
        height={14}
        disabled={disabled}
        color={
          done
            ? colors.base.emerald600
            : disabled
            ? colors.neutral.textDisabled
            : colors.neutral.textStrong
        }
        type={done ? 'filled' : undefined}
      />
      {labelYear && (
        <>
          <div className="flex flex-col" style={{ width: 'calc(100% - 50px)' }}>
            <Typography
              className="ml-spacing-14 flex-1 text-left"
              variant="LabelSmall500"
              color={done ? 'emerald600' : disabled ? 'gray400' : 'gray600'}
              wrap
              pointer
              clip
            >
              {label}
            </Typography>
          </div>
          <Typography
            className="ml-spacing-14 flex-1 text-right"
            variant="LabelSmall500"
            color={done ? 'emerald600' : disabled ? 'gray400' : 'gray600'}
            wrap
            pointer
          >
            {labelYear}
          </Typography>
        </>
      )}

      {!labelYear && (
        <Typography
          className="ml-spacing-14 flex-1 text-left"
          variant="LabelSmall500"
          color={done ? 'emerald600' : disabled ? 'gray400' : 'gray600'}
          wrap
          pointer
        >
          {label}
        </Typography>
      )}
    </button>
  );
};

export default AnchorCategory;
