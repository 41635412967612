import { IIconProps } from '../interface';

const SvgBankBuilding = ({
  width = 24,
  height = 24,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          version="1.1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <path d="M0,0h24v24h-24v-24Z" id="a"></path>
          </defs>
          <g fill="none">
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M15.2,8.16c-0.867,-0.724 -1.982,-1.16 -3.2,-1.16c-2.761,0 -5,2.239 -5,5c0,2.761 2.239,5 5,5c2.761,0 5,-2.239 5,-5h-4.375"
            ></path>
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M7.5,3h9.004c2.483,0 4.496,2.013 4.496,4.496v9.009c0,2.482 -2.013,4.495 -4.496,4.495h-9.008c-2.483,0 -4.496,-2.013 -4.496,-4.496v-9.004c0,-2.485 2.015,-4.5 4.5,-4.5Z"
            ></path>
          </g>
        </svg>
      );
  }
};

export default SvgBankBuilding;
