import { IIconProps } from '../interface';

const SvgHome = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1702_11940)">
            <path
              d="M4 15.7283L14.5733 6.64831V6.63498C15.3867 5.92831 16.5867 5.92831 17.4 6.63498L27.9733 15.7016"
              stroke="#323232"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.667 28.1418V6.14176C26.667 5.76843 26.3603 5.4751 26.0003 5.4751H22.0003H21.987C21.6136 5.4751 21.3203 5.76843 21.3203 6.14176V9.60843"
              stroke="#323232"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.7199 28.1416V20.8083C12.7066 20.0616 13.3066 19.4616 14.0399 19.4616H17.9066H17.8933C18.6266 19.4483 19.2266 20.0483 19.2266 20.7816V28.115"
              stroke="#323232"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.70703 14.2617V28.1284"
              stroke="#323232"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M28 28.1416H4"
              stroke="#323232"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1702_11940">
              <rect
                width="32"
                height="32"
                fill="white"
                transform="translate(0 0.141602)"
              />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

export default SvgHome;
