import { IIconProps } from '../interface';

const SvgMalta = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 0C43.4639 0 56 12.5361 56 28C56 43.4639 43.4639 56 28 56"
      fill="#D80027"
    />
    <path
      d="M19.4782 10.9565V7.30438H15.8261V10.9565H12.1738V14.6087H15.8261V18.2609H19.4782V14.6087H23.1304V10.9565H19.4782Z"
      fill="#ACABB1"
    />
  </svg>
);

export default SvgMalta;
