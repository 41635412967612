import { IIconProps } from '../interface';

const SvgSlovenia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16611)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M54.2584 18.2609H24.3479V10.9565H9.73908V18.2609H1.74158C0.616109 21.2941 0 24.575 0 28C0 31.425 0.616109 34.7058 1.74158 37.7391L28 40.1739L54.2584 37.7391C55.3839 34.7058 56 31.425 56 28C56 24.575 55.3839 21.2941 54.2584 18.2609Z"
        fill="#0052B4"
      />
      <path
        d="M28 56C40.039 56 50.3022 48.4015 54.2584 37.7391H1.74158C5.69778 48.4015 15.961 56 28 56Z"
        fill="#D80027"
      />
      <path
        d="M9.73914 18.2609V20.6956C9.73914 26.287 17.0435 28.0001 17.0435 28.0001C17.0435 28.0001 24.3479 26.287 24.3479 20.6956V18.2609L21.9131 20.6957L17.0435 17.0436L12.1739 20.6957L9.73914 18.2609Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16611">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSlovenia;
