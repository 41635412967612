import { IIconProps } from '../interface';

const SvgMyanmar = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.2584 37.7391C55.384 34.7059 56 31.425 56 28C56 24.575 55.384 21.2941 54.2584 18.2609L28 15.8261L1.74158 18.2609C0.616109 21.2941 0 24.575 0 28C0 31.425 0.616109 34.7059 1.74158 37.7391L28 40.1739L54.2584 37.7391Z"
      fill="#6DA544"
    />
    <path
      d="M54.2585 18.2609C50.3023 7.5985 40.0391 0 28.0001 0C15.9611 0 5.6979 7.5985 1.7417 18.2609H54.2585Z"
      fill="#FFDA44"
    />
    <path
      d="M28.0001 56C40.0391 56 50.3023 48.4015 54.2585 37.7391H1.7417C5.6979 48.4015 15.9611 56 28.0001 56Z"
      fill="#D80027"
    />
    <path
      d="M47.2007 23.6891H32.5327L28 9.73907L23.4673 23.6891H8.79932L20.666 32.3107L16.1332 46.2609L28 37.7391L39.8667 46.2608L35.3341 32.3106L47.2007 23.6891Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgMyanmar;
