import { t } from 'i18next';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../customHooks/useWindowDimensions';
import { Grid, Icon, Typography } from '../../design-system';
import { colors } from '../../design-system/theme';
import { IRootState } from '../../store';
import { useSelector } from 'react-redux';
import { supabase } from '../../supabaseClient';
import getUrlParameters from '../../utils/getUrlParameters';
import { showChat } from '../../utils/frontChatUtils';

interface IAppGoBack {
  gobackText: string;
  gobackAction: () => void;
  helpButton?: boolean;
  helpAction?: () => void;
  asSidebar?: boolean;
}
interface IAppGoBackParams {
  customGobackText?: string;
  customGobackAction?: () => void;
  customHelpButton?: boolean;
  customHelpAction?: () => void;
  customAsSidebar?: boolean;
  children?: ReactElement;
}

const AppGoBack = ({
  children,
  customGobackAction,
  customGobackText = '',
  customAsSidebar = false,
  customHelpAction,
  customHelpButton = false,
}: IAppGoBackParams) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { helpButton: helpButtonState } = useSelector(
    (state: IRootState) => state.headerReducer
  );

  useEffect(() => {
    const handleLoggedIn = async () => {
      const sessionData = await supabase.auth.getSession();
      if (!!sessionData?.data.session?.user) {
        setIsLoggedIn(true);
      }

      setIsLoggedIn(false);
      return;
    };

    handleLoggedIn();
  }, []);
  const { fiscalYear: year } = useSelector(
    (state: IRootState) => state.tatReducer
  );

  const fiscalYear =
    getUrlParameters('y') || year || localStorage.getItem('year');

  const contentByRoute: { [key: string]: IAppGoBack } = {
    '/taxyear': {
      gobackText: t('header.back_home'),
      gobackAction: () => navigate('/'),
      helpButton: true,
      helpAction: showChat,
    },
    '/ismobile': {
      gobackText: t('header.back_login'),
      gobackAction: () => navigate('/login'),
    },
    '/taxallocation': {
      gobackText: t('header.back_overview', { fiscalYear }),
      gobackAction: () => navigate('/taxyear'),
      asSidebar: true,
      helpButton: false,
      helpAction: showChat,
    },
    '/verify': {
      gobackText: t('header.back_overview', { fiscalYear }),
      gobackAction: () => navigate('/taxyear'),
    },
    '/taxallocation/questions': {
      gobackText: t('header.back_income_categories'),
      gobackAction: () =>
        navigate(`/taxallocation/categories${window.location.search}`),
      helpButton: true,
      helpAction: showChat,
    },
    '/taxallocation/categories': {
      gobackText: t('header.back_overview', { fiscalYear }),
      gobackAction: () => navigate('/taxyear'),
      helpButton: true,
      helpAction: showChat,
    },
    '/taxyear/select-countries': {
      gobackText: t('header.back_overview', { fiscalYear }),
      gobackAction: () => navigate('/taxyear'),
      asSidebar: true,
    },
    '/additionalcomplexities': {
      gobackText: t('header.back_overview', { fiscalYear }),
      gobackAction: () => navigate('/taxyear'),
      asSidebar: true,
    },
    '/verify-with-us': {
      gobackText: t('header.back_overview', { fiscalYear }),
      gobackAction: () => navigate('/taxyear'),
    },
    '/payment/summary': {
      gobackText: t('header.back_overview', { fiscalYear }),
      gobackAction: () => navigate('/taxyear'),
    },
    '/payment/checkout': {
      gobackText: t('header.back_summary'),
      gobackAction: () => navigate(`/payment/summary${window.location.search}`),
    },
    '/output/taxresidenceknown': {
      gobackText: t('header.back_overview', { fiscalYear }),
      gobackAction: () => navigate('/taxyear'),
    },
    '/schedulesuccess': {
      gobackText: t('header.back_overview', { fiscalYear }),
      gobackAction: () => navigate('/taxyear'),
    },
    default: {
      gobackText: t('header.back_home'),
      gobackAction: () => navigate('/'),
    },
  };

  const { gobackText, gobackAction, helpButton, helpAction, asSidebar } =
    useMemo(() => {
      const routeContent =
        contentByRoute[pathname] || contentByRoute['default'];

      if (children) {
        return {
          gobackText: customGobackText,
          gobackAction: customGobackAction,
          helpButton: customHelpButton,
          helpAction: customHelpAction,
          asSidebar: customAsSidebar,
        };
      }

      if (routeContent.asSidebar) {
        routeContent.asSidebar = width > 1060;
      }

      if (helpButtonState) {
        routeContent.helpButton = true;
      }

      return { ...routeContent };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, width, helpButtonState]);

  const GoBackButton = ({ className }: { className?: string }) => {
    return (
      <button
        onClick={gobackAction}
        className={`flex flex-row items-center cursor-pointer ${className}`}
      >
        <Icon name="ArrowLeft" width={12} height={10} color={'#777A83'} />
        <Typography
          variant="LabelSmall500"
          className="ml-spacing-10"
          color="gray500"
        >
          {gobackText}
        </Typography>
      </button>
    );
  };

  return (
    <div
      className={`flex min-h-screen bg-base-gray25 ${
        asSidebar ? 'flex-row' : 'flex-col'
      }`}
    >
      <Grid
        noPadding
        fluid
        className={`justify-between z-30 bg-base-gray25 px-spacing-32 py-spacing-32 ${
          asSidebar
            ? 'flex-col min-h-screen w-spacing-188'
            : 'flex-row w-full !fixed'
        }`}
      >
        <div
          className={`flex ${
            asSidebar ? 'flex-col fixed' : 'flex-row items-center'
          }`}
        >
          <button
            className={`${asSidebar ? 'mb-spacing-48' : 'mr-spacing-140 '}`}
            onClick={() =>
              isLoggedIn
                ? navigate('/')
                : (window.location.href = 'https://tytle.io/')
            }
          >
            <Icon name="Logo" width={91} height={35} />
          </button>
          <GoBackButton />
        </div>
        <>
          {helpButton && (
            <div className={asSidebar ? 'fixed right-spacing-32' : ''}>
              <button
                className="flex flex-row w-auto py-spacing-8 px-spacing-12 gap-2 bg-neutral-field rounded-lg"
                onClick={helpAction}
              >
                <Icon
                  name="Question"
                  type="filled"
                  width={16}
                  height={16}
                  color={colors.base.gray500}
                />
                <Typography variant={'LabelSmall500'} color="gray500">
                  {t('header.help')}
                </Typography>
              </button>
            </div>
          )}
        </>
      </Grid>
      <Grid
        noPadding
        fluid
        className={`flex-1 bg-base-gray25 overflow-x-hidden ${
          helpButton ? 'pt-spacing-100' : 'pt-spacing-64'
        } ${asSidebar && '!pt-0'}`}
      >
        {children || <Outlet />}
      </Grid>
    </div>
  );
};

export default AppGoBack;
