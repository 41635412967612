import { IconsList, IconsListEnum } from '../../design-system/Icon/icons/enum';

const categeriesFlows: {
  [key: string]: { id: string; label: string; icon: IconsList };
} = {
  category_1: {
    id: 'category_1',
    label: 'sidebar.general.label',
    icon: IconsListEnum.Home,
  },
  category_2: {
    id: 'category_2',
    label: 'sidebar.wrapping_up.label',
    icon: IconsListEnum.Info,
  },
};

export default categeriesFlows;
