import { IIconProps } from '../interface';

const SvgGibraltar = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F9F9F9"
    />
    <path
      d="M28 56C40.0389 56 50.3022 48.4015 54.2583 37.7391H1.74146C5.69777 48.4015 15.9609 56 28 56Z"
      fill="#D80027"
    />
    <path
      d="M38.9565 23.1304V19.4783H40.1738V17.0435H37.739V18.2609H35.3044V17.0435H32.8696V19.4783H34.0869V23.1304H31.6521V14.6089H32.8696V12.1742H30.4348V13.3913H29.2173V12.1742H26.7826V13.3913H25.5652V12.1742H23.1304V14.6089H24.3478V23.1304H21.913V19.4783H23.1304V17.0435H20.6956V18.2609H18.2609V17.0435H15.8261V19.4783H17.0434V23.1304H14.6086V32.8696H41.3913V23.1304H38.9565Z"
      fill="#D80027"
    />
    <path
      d="M28 31.6521C25.983 31.6521 24.3478 33.2873 24.3478 35.3043C24.3478 36.8941 25.3644 38.2465 26.7826 38.7478V43.8261H23.1305V48.6957H29.2174V38.7479C30.6357 38.2466 31.6522 36.8944 31.6522 35.3044C31.6521 33.2874 30.017 31.6521 28 31.6521ZM28 36.5217C27.3276 36.5217 26.7826 35.9766 26.7826 35.3044C26.7826 34.6321 27.3276 34.087 28 34.087C28.6723 34.087 29.2173 34.6321 29.2173 35.3044C29.2173 35.9766 28.6723 36.5217 28 36.5217Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgGibraltar;
