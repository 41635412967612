import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './languages';
import { Provider } from 'react-redux';
import store from './store';
import Routes from './Routes';
import { Toaster } from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import InitChat from './InitChat';

const container = document.getElementById('root');

const root = createRoot(container!);

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || '');
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  ignoreErrors: ['ChunkLoadError', /chunk/gi],
});

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Routes />
      <Toaster />
      <ReactQueryDevtools />
      <InitChat />
    </Provider>
  </QueryClientProvider>
);
console.log('lol');
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
