import React, { useMemo } from 'react';
import Icon from '../../design-system/Icon';
import { colors } from '../../design-system/theme';
import Typography from '../../design-system/Typography';
import { IconsList } from '../Icon/icons/enum';
import { Link, useLocation } from 'react-router-dom';
import { getTwoInitialLetters } from '../../utils/formatUtils';

export interface IAnchor {
  label: string;
  path: string;
  description?: string;
  avatar?: string;
  icon?: IconsList;
  className?: string;
}

const renderAnchorImage = (
  label: string,
  icon?: IconsList,
  avatar?: string,
  description?: string
) => {
  if (!avatar && !icon) {
    const initials = getTwoInitialLetters(label || description || '');
    return (
      <div className=" w-spacing-32 h-spacing-32 rounded-full flex justify-center items-center bg-indigo-500 uppercase">
        <Typography variant="BodyDefault500" color="white">
          {initials}
        </Typography>
      </div>
    );
  }
  if (avatar && !icon) {
    return (
      <img
        src={avatar}
        width={32}
        height={32}
        className="rounded-full"
        alt="User avatar"
      />
    );
  }
  if (icon) {
    return (
      <Icon
        name={icon}
        width={24}
        height={24}
        color={colors.neutral.textWeak}
      />
    );
  }
};

const Anchor = ({
  label,
  description,
  avatar,
  icon,
  className,
  path,
}: IAnchor) => {
  const location = useLocation();

  const isActive = useMemo(
    () => location.pathname === path,
    [location.pathname, path]
  );

  return (
    <Link to={path}>
      <button
        className={`${className} Anchor transition-all cursor-pointer flex px-spacing-18 py-spacing-10 w-full rounded-lg 
          hover:bg-neutral-fieldHover active:bg-neutral-fieldActive items-center ${
            isActive ? 'bg-neutral-field' : 'bg-white'
          }`}
      >
        {renderAnchorImage(label, icon, avatar, description)}
        {description ? (
          <div className="flex flex-col flex-1 items-start ml-spacing-12 overflow-hidden">
            <Typography variant="LabelSmall500" color="gray600" clip pointer>
              {label}
            </Typography>
            <Typography variant="LabelXsmall400" color="gray500" clip pointer>
              {description}
            </Typography>
          </div>
        ) : (
          <div className="flex flex-col flex-1 items-start ml-spacing-12 overflow-hidden self-center">
            <Typography
              variant="LabelSmall500"
              color="gray600"
              wrap
              pointer
              className="text-left"
            >
              {label}
            </Typography>
          </div>
        )}
      </button>
    </Link>
  );
};

export default Anchor;
