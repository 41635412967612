export enum IconsListEnum {
  Circle = 'Circle',
  Check = 'Check',
  Plus = 'Plus',
  Minus = 'Minus',
  Close = 'Close',
  Pause = 'Pause',
  Play = 'Play',
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
  Question = 'Question',
  BurgerMenu = 'BurgerMenu',
  Globe = 'Globe',
  Home = 'Home',
  Heart = 'Heart',
  Ring = 'Ring',
  ChevronDown = 'ChevronDown',
  KeyEnter = 'KeyEnter',
  SuitCase = 'SuitCase',
  InvestimentAdd = 'InvestimentAdd',
  Chair = 'Chair',
  HouseHandshake = 'HouseHandshake',
  HouseKey = 'HouseKey',
  SuitCaseWorking = 'SuitCaseWorking',
  UserProfile = 'UserProfile',
  OfficeChairTable = 'OfficeChairTable',
  PlaneGlobe = 'PlaneGlobe',
  MapsNavigation = 'MapsNavigation',
  MapsNavigationMultiple = 'MapsNavigationMultiple',
  MoneyCoins = 'MoneyCoins',
  GroupUser = 'GroupUser',
  Bank = 'Bank',
  Chess = 'Chess',
  InvoiceHandSelect = 'InvoiceHandSelect',
  DocumentUpload = 'DocumentUpload',
  Document = 'Document',
  MoneyBankNoteArrow = 'MoneyBankNoteArrow',
  BabyChild = 'BabyChild',
  EngagementRings = 'EngagementRings',
  Romance = 'Romance',
  Message = 'Message',
  Logo = 'Logo',
  Barchart = 'Barchart',
  Subscription = 'Subscription',
  BankBuilding = 'BankBuilding',
  Car = 'Car',
  Membership = 'Membership',
  House = 'House',
  Doctor = 'Doctor',
  Insurance = 'Insurance',
  Crypto = 'Crypto',
  Browser = 'Browser',
  GlobePin = 'GlobePin',
  Contract = 'Contract',
  Entrepreneur = 'Entrepreneur',
  Employee = 'Employee',
  Other = 'Other',
  Leave = 'Leave',
  Google = 'Google',
  Passport = 'Passport',
  PassportPages = 'PassportPages',
  Coins = 'Coins',
  Calendar = 'Calendar',
  UserGroup = 'UserGroup',
  InvoiceTextList = 'InvoiceTextList',
  Location = 'Location',
  Pin = 'Pin',
  Attachment = 'Attachment',
  CleaningWindowShine = 'CleaningWindowShine',
  Money = 'Money',
  Phone = 'Phone',
  CleaningRushStarts = 'CleaningRushStarts',
  Arrow = 'Arrow',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Exchange = 'Exchange',
  Party = 'Party',
  Lock = 'Lock',
  Trailing = 'Trailing',
  Trash = 'Trash',
  Handkey = 'Handkey',
  BusinessChart = 'BusinessChart',
  GoldBars = 'GoldBars',
  HomePriceIncrease = 'HomePriceIncrease',
  Bitcoin = 'Bitcoin',
  Dollar = 'Dollar',
  CashProtection = 'CashProtection',
  MoneyCoinsOne = 'MoneyCoinsOne',
  MoneyBankNote = 'MoneyBankNote',
  Piggy = 'Piggy',
  DollarSend = 'DollarSend',
  CasinoSlot = 'CasinoSlot',
  SingleUserHeart = 'SingleUserHeart',
  SendMoneyPerson = 'SendMoneyPerson',
  HomeCustomer = 'HomeCustomer',
  LoadingCheck = 'LoadingCheck',
  TaskList = 'TaskList',
  FilterSort = 'FilterSort',
  DashboardMinimal = 'DashboardMinimal',
  SendMessage = 'SendMessage',
  GraduateHat = 'GraduateHat',
  DollarCurrencyShield = 'DollarCurrencyShield',
  GovernmentBuilding = 'GovernmentBuilding',
  ChartCircle = 'ChartCircle',
  MoneyBag = 'MoneyBag',
  RealState = 'RealState',
  CryptoEth = 'CryptoEth',
  CryptoBtc = 'CryptoBtc',
  CryptoStablecoins = 'CryptoStablecoins',
  CryptoNft = 'CryptoNft',
  Weather1 = 'Weather1',
  Weather2 = 'Weather2',
  Weather3 = 'Weather3',
  Weather4 = 'Weather4',
  Weather5 = 'Weather5',
  Weather6 = 'Weather6',
  Weather7 = 'Weather7',
  Weather8 = 'Weather8',
  Weather9 = 'Weather9',
  Salary = 'Salary',
  CertificateMedal = 'CertificateMedal',
  CourtLegalLawDecision = 'CourtLegalLawDecision',
  GlobeMoneyCurrency = 'GlobeMoneyCurrency',
  BankNoteHand = 'BankNoteHand',
  CashBankNoteCoins = 'CashBankNoteCoins',
  BookLegal = 'BookLegal',
  TravelCompass = 'TravelCompass',
  PenEdit = 'PenEdit',
  SelectObject = 'SelectObject',
  Wave = 'Wave',
  ArrowRightOutline = 'ArrowRightOutline',
}

export type IconsList = keyof typeof IconsListEnum;
