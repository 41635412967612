import { IIconProps } from '../interface';

const SvgSuitCaseWorking = ({
  width = 24,
  height = 24,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.05546 3.05546C3.57118 2.53973 4.27065 2.25 5 2.25H17C17.7293 2.25 18.4288 2.53973 18.9445 3.05546C19.4603 3.57118 19.75 4.27065 19.75 5V8C19.75 8.41421 19.4142 8.75 19 8.75C18.5858 8.75 18.25 8.41421 18.25 8V5C18.25 4.66848 18.1183 4.35054 17.8839 4.11612C17.6495 3.8817 17.3315 3.75 17 3.75H5C4.66848 3.75 4.35054 3.8817 4.11612 4.11612C3.8817 4.35054 3.75 4.66848 3.75 5V19C3.75 19.3315 3.8817 19.6495 4.11612 19.8839C4.35054 20.1183 4.66848 20.25 5 20.25H9C9.41421 20.25 9.75 20.5858 9.75 21C9.75 21.4142 9.41421 21.75 9 21.75H5C4.27065 21.75 3.57118 21.4603 3.05546 20.9445C2.53973 20.4288 2.25 19.7293 2.25 19V5C2.25 4.27065 2.53973 3.57118 3.05546 3.05546Z"
            fill={color}
          />
          <path
            d="M6.25 8C6.25 7.58579 6.58579 7.25 7 7.25H13C13.4142 7.25 13.75 7.58579 13.75 8C13.75 8.41421 13.4142 8.75 13 8.75H7C6.58579 8.75 6.25 8.41421 6.25 8Z"
            fill={color}
          />
          <path
            d="M7 11.25C6.58579 11.25 6.25 11.5858 6.25 12C6.25 12.4142 6.58579 12.75 7 12.75H9C9.41421 12.75 9.75 12.4142 9.75 12C9.75 11.5858 9.41421 11.25 9 11.25H7Z"
            fill={color}
          />
          <path
            d="M6.25 16C6.25 15.5858 6.58579 15.25 7 15.25H8C8.41421 15.25 8.75 15.5858 8.75 16C8.75 16.4142 8.41421 16.75 8 16.75H7C6.58579 16.75 6.25 16.4142 6.25 16Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5126 10.7626C14.8408 10.4344 15.2859 10.25 15.75 10.25H18.25C18.7141 10.25 19.1592 10.4344 19.4874 10.7626C19.8156 11.0908 20 11.5359 20 12V13.25H20.5C21.7426 13.25 22.75 14.2574 22.75 15.5V19.5C22.75 20.7426 21.7426 21.75 20.5 21.75H13.5C12.2574 21.75 11.25 20.7426 11.25 19.5V15.5C11.25 14.2574 12.2574 13.25 13.5 13.25H14V12C14 11.5359 14.1844 11.0908 14.5126 10.7626ZM18.5 12V13.25H15.5V12C15.5 11.9337 15.5263 11.8701 15.5732 11.8232C15.6201 11.7763 15.6837 11.75 15.75 11.75H18.25C18.3163 11.75 18.3799 11.7763 18.4268 11.8232C18.4737 11.8701 18.5 11.9337 18.5 12ZM13.5 14.75H20.5C20.9142 14.75 21.25 15.0858 21.25 15.5V19.5C21.25 19.9142 20.9142 20.25 20.5 20.25H13.5C13.0858 20.25 12.75 19.9142 12.75 19.5V15.5C12.75 15.0858 13.0858 14.75 13.5 14.75Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgSuitCaseWorking;
