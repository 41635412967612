import { IIconProps } from '../interface';

const SvgOfficeChairTable = ({
  width = 24,
  height = 24,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.25 3V8.25H1C0.585786 8.25 0.25 8.58579 0.25 9C0.25 9.41421 0.585786 9.75 1 9.75H2.25V19C2.25 19.4142 2.58579 19.75 3 19.75C3.41421 19.75 3.75 19.4142 3.75 19V9.75H18.25V19C18.25 19.4142 18.5858 19.75 19 19.75C19.4142 19.75 19.75 19.4142 19.75 19V9.75H21C21.4142 9.75 21.75 9.41421 21.75 9C21.75 8.58579 21.4142 8.25 21 8.25H15.75V3C15.75 2.27065 15.4603 1.57118 14.9445 1.05546C14.4288 0.539731 13.7293 0.25 13 0.25H9C8.27065 0.25 7.57118 0.539731 7.05546 1.05546C6.53973 1.57118 6.25 2.27065 6.25 3ZM9 1.75C8.66848 1.75 8.35054 1.8817 8.11612 2.11612C7.8817 2.35054 7.75 2.66848 7.75 3V8.25H14.25V3C14.25 2.66848 14.1183 2.35054 13.8839 2.11612C13.6495 1.8817 13.3315 1.75 13 1.75H9Z"
            fill="#36373F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.75 15.75H14.5C15.0967 15.75 15.669 15.5129 16.091 15.091C16.5129 14.669 16.75 14.0967 16.75 13.5V13C16.75 12.5359 16.5656 12.0908 16.2374 11.7626C15.9092 11.4344 15.4641 11.25 15 11.25H7C6.53587 11.25 6.09075 11.4344 5.76256 11.7626C5.43437 12.0908 5.25 12.5359 5.25 13V13.5C5.25 14.0967 5.48705 14.669 5.90901 15.091C6.33097 15.5129 6.90326 15.75 7.5 15.75H10.25V18.25H8C7.58579 18.25 7.25 18.5858 7.25 19C7.25 19.4142 7.58579 19.75 8 19.75H14C14.4142 19.75 14.75 19.4142 14.75 19C14.75 18.5858 14.4142 18.25 14 18.25H11.75V15.75ZM6.82322 12.8232C6.87011 12.7763 6.9337 12.75 7 12.75H15C15.0663 12.75 15.1299 12.7763 15.1768 12.8232C15.2237 12.8701 15.25 12.9337 15.25 13V13.5C15.25 13.6989 15.171 13.8897 15.0303 14.0303C14.8897 14.171 14.6989 14.25 14.5 14.25H7.5C7.30109 14.25 7.11032 14.171 6.96967 14.0303C6.82902 13.8897 6.75 13.6989 6.75 13.5V13C6.75 12.9337 6.77634 12.8701 6.82322 12.8232Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgOfficeChairTable;
